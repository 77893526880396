<template>
  <div class="thumbnails">
    <div class="banner thumbnails__imgWrap">
     <img class="banner__img thumbnails__img" src="../assets/img/banners/password-banner.jpg" alt="">
    </div>
    <div class="thumbnails__textWrap">
      <p class="titlePage titlePage_subtitle text_green">WELCOME TO</p><br>
      <h1 class="titlePage">STARLINE<br><span class="text_green">SECURITY </span></h1>
      <p class="text text_default">Thanks for choosing Starline Security and welcome to our wholesale system! Before you start using
        our system, you will need to create a password.</p>
      <p class="text text_default">Please make sure this password is unique to you and include:</p>
      <ul>
        <li class="text text_default">At least 6 characters.</li>
        <li class="text text_default">One lower-case letter</li>
        <li class="text text_default">One upper-case letter</li>
        <li class="text text_default">One number</li>
      </ul>
      <Form  action="" class="form" @submit="handlePassword">
        <label class="form__label">
          <span class="form__labelTitle">Create Password</span>
          <Field type="password" class="form__input"
                 placeholder="Create Password"
                 name="password"
                 id="password"
                 :rules="{required: true, min: 6}"
                 v-model="password"
          />
          <ErrorMessage class="form__error"  name="password" />
        </label>
        <label class="form__label">
          <span class="form__labelTitle">Confirm Password</span>
          <Field type="password" class="form__input"
                 placeholder="Confirm Password"
                 name="confirm password"
                 label="password"
                 :rules="'required|confirmed:@password'"
                 v-model="password_confirmation"
          />
          <ErrorMessage class="form__error"  name="confirm password" />

        </label>

        <button type="submit" class="btn btn_default"><svg-icon name="solid/unlock-alt" /> CREATE PASSWORD</button>
      </Form>
    </div>

    <div class="modal modal_wrap" v-show="isOpen">
      <div class="modal_popup">
        <h2 class="form__title">OOPS! SOMETHING WENT WRONG!</h2>
        <p class="text text_green text_italic">Please check the following fields and try again:</p>
        <ul>
          <li class="text_white" v-for="(item, index) in errorList"
              :key="index"
          >
            {{item}}
          </li>
        </ul>
        <button class="btn btn_transparent" @click="closePopup"><svg-icon name="solid/times" /> Close this window</button>
      </div>
    </div>

  </div>
</template>

<script>

  export default {
    name: "CreatePassword",
    data(){
      return {
        password: '',
        password_confirmation: '',
        uid: ''
      }
    },
    beforeCreate() {
        this.$store.dispatch('createPassword/checkUID', this.$route.params.param);
    },
    computed: {
      isOpen(){
        return this.$store.getters['createPassword/isOpen'];
      },

      errorList(){
        return this.$store.getters['createPassword/errorsList']
      },
    },

    methods: {
      closePopup(){
        return this.$store.commit('createPassword/closePopup');
      },

      handlePassword(){
        this.getUid();
       /* this.$validator.validate().then(valid => {
          if (valid) {*/
            this.$store.dispatch('createPassword/sendPass', {
              password: this.password,
              password_confirmation: this.password_confirmation,
              uid: this.uid
            });
        /*  }
        })*/
      },

      getUid(){
        this.uid = this.$route.params.param;
      },

    }
  }
</script>

<style scoped lang="scss">

  ul{
    width: 100%;
    list-style-type:disc;
    li{
      display: list-item;
      list-style: disc inside !important;
    }
  }

  .form{
    width: 100%;
    max-width: 500px;
  }
  .thumbnails {
    //min-height : 100% !important;
    &__imgWrap {
      height : 100vh;
      min-height: calc(100vh - 110px);
      background-image: url('http://localhost:8080/img/password-banner.352bc3ba.jpg');
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
    }
  }
/*  ::v-deep.container_fluid{
background-color : red;
  }*/
</style>
